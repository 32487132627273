.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 20px;
  font-family: var(--body-font);
}

.community-section {
  text-align: center;
  max-width: 800px;
}

.community-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.community-icon {
  width: 600px;
  height: 600px;
  object-fit: fill;
}



.join-community-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.join-community-button:hover {
  background-color: #0056b3;
}

.learning-section {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  max-width: 1000px;
  font-family: var(--body-font);
}

.learning-image img {
  max-width: 300px;
  object-fit: contain;
}

.learning-content {
  max-width: 500px;
  font-family: var(--body-font);
}

.learning-content-para {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mentorguru-highlight {
  color: #007bff;
}

.start-now-button {
  background-color: #ff6a00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.start-now-button:hover {
  background-color: #e65a00;
}

@media (min-width: 768px) {
  .whatsapp img {
    height: 30px !important;
    width: 30px !important;
   }
} 

@media (max-width: 768px) {
  .learning-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .community-icon {
    width: 350px;
    height: 350px;

  }
}

@media (max-width: 480px) {
  .community-icons img {
    width: 100%;
  }
  .community-icon {
    width: 350px;
    height: 350px;

  }
}
