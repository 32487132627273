.home {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
  padding: 0 20px;
}

.home__heading { 
  font-family: var(--body-font);
  font-size: 3vw !important; /* Responsive font size */
  color: #ff6a00;
  font-weight: bold;
  margin: 0; 
  margin-top: 20vh;
}

.home__sub__heading {
  font-family: var(--body-font);
  font-size: 2.5vw; /* Responsive font size */
  color: #007bff;
  font-weight: var(--font-medium);
  margin: 0;
}

span {
  font-family: var(--body-font);
  font-size: 2vw !important; /* Responsive font size */
  color: #202020;
  font-weight: var(--font-medium);
  margin-bottom: -15px;
}

.home__text {
  color: black;
  font-size: 1.2vw; /* Responsive font size */
  margin-top: 10px;
}

.home__buttom {
  background-color: #ff6a00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--body-font);
  margin-top: 20px;
  margin-bottom: 20px;
}

.home__buttom:hover {
  background-color: #e65a00;
}

.home__img {
  height: auto;
  max-width: 100%; /* Ensures the image doesn’t exceed its container */
  margin-top: 20px;
  border-radius: 10px; /* Optional: Add rounded corners */
  margin-bottom: 5vh;
}

/* Updated CSS for Features Section */
.home__features {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px; /* Space between the features */
  margin-top: 20px;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000; /* Black text */
}

.feature__underline {
  width: 80%; /* Adjust the underline width */
  height: 4px;
  background-color: #ff9900; /* Orange underline color */
  border-radius: 2px;
  margin-top: 15px;
  transform: scaleX(0.8); /* Slightly narrower than the text */
}

/* Overlay for Login */
/* Overlay for Login */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay__content {
  background: #fff;
  padding: 40px 60px;
  border-radius: 12px;
  text-align: center;
  width: 100%;
  max-width: 400px; /* Limit popup width */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.overlay__content p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.overlay__login-button {
  background-color: #ff6a00;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overlay__login-button:hover {
  background-color: #e65c00;
}

/* Slider Adjustments */
.slider {
  position: relative;
  width: 100%;
  max-width: 900px; /* Set maximum width for the slider */
  margin: 30px auto;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: auto !important;
  align-items: center;
  background: transparent !important; /* Remove background color */
}

.slider img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image fills the slider without distortion */
  display: block;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
}

.slider img.active {
  opacity: 1;
  position: relative;
}

/* Slider Controls */
.slider-controls {
  position: absolute;
  bottom: 15px; /* Position below the slider */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 12px;
}

.slider-button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slider-controls .slider-button {
  font-size: 1.5rem;
  padding: 8px 16px;
}


/* Media Query for larger screens */
@media (min-width: 1024px) {
  .home__heading {
    font-size: 6rem; /* Larger font size for big screens */
  }
  .home__sub__heading {
    font-size: 3rem;
  }
  .home__text {
    font-size: 2rem;
  }
  .home__img {
    max-width: 800px;
    height: auto;
  }
}

/* Media Query for big screens */
@media (max-width: 1024px) {
  .home__heading {
    font-size: 6rem; /* Larger font size for big screens */
  }
  .home__sub__heading {
    font-size: 3rem;
  }
  .home__text {
    font-size: 2rem;
  }
  .home__img {
    max-width: 600px; /* Limit the image size */
    height: auto;
  }
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .home__heading {
    font-size: 6vw !important;
    font-weight: bold;
    margin: 0;
    margin-top: 10vh;
  }
  .home__sub__heading {
    font-size: 1.6rem !important;
  }
  .home__text {
    font-size: 1rem;
  }
  .home__img {
    max-width: 400px; /* Adjust image size for medium screens */
    height: auto;
  }

  span {
    font-family: var(--body-font);
    font-size: 4vw !important; /* Responsive font size */
    color: #202020;
    font-weight: var(--font-medium);
    margin-bottom: 3px;
    margin-top: 5px;
  }

  .feature__underline {
    width: 80%; /* Adjust the underline width */
    height: 4px;
    background-color: #ff9900; /* Orange underline color */
    border-radius: 2px;
    margin-top: 10px !important;
    transform: scaleX(0.8); /* Slightly narrower than the text */
  }

  .slider {
    max-width: 600px;
  }

  .slider-button {
    font-size: 1.2rem;
    padding: 8px 14px;
  }

  .overlay__content {
    padding: 20px;
    width: 90%; /* Adjust width for mobile screens */
  }

  .overlay__content p {
    font-size: 1.2rem; /* Smaller font size */
  }

  .overlay__login-button {
    font-size: 1rem; /* Smaller button text */
    padding: 10px 20px; /* Smaller button padding */
  }
}

@media (max-width: 480px) {
  .home__heading {
    font-size: 3.5rem;
  }
  .home__sub__heading {
    font-size: 1.5rem;
  }
  .home__text {
    font-size: 1rem;
  }
  .home__img {
    max-width: 350px; /* Adjust image size for small screens */
    height: auto;
  }

  span {
    font-family: var(--body-font);
    font-size: 4vw !important; /* Responsive font size */
    color: #202020;
    font-weight: var(--font-medium);
  }

  .feature__underline {
    width: 80%; /* Adjust the underline width */
    height: 4px;
    background-color: #ff9900; /* Orange underline color */
    border-radius: 2px;
    margin-top: 5px !important;
    transform: scaleX(0.8); /* Slightly narrower than the text */
  }

  .slider {
    max-width: 400px;
  }

  .slider-button {
    font-size: 1rem;
    padding: 6px 12px;
  }

  .overlay__content {
    padding: 15px;
    width: 95%; /* Further adjust width for smaller screens */
  }

  .overlay__content p {
    font-size: 1rem; /* Even smaller font size for extra small screens */
  }

  .overlay__login-button {
    font-size: 0.9rem; /* Smaller button text */
    padding: 8px 16px; /* Adjust padding for extra small screens */
  }
}
