/* BlogPage.css */
.blog-container {
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    padding: 20px;
    margin-top: 10vh;
  }
  
  .blog-header {
    text-align: center;
    background: linear-gradient(to right, #000, #444);
    color: white;
    padding: 40px 0;
  }
  
  .blog-title {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .blog-caption {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .blog-main-content {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .latest-blogs {
    flex: 2;
  }
  
  .blog-post {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .blog-thumbnail {
    width: 100%;
    border-radius: 5px;
  }
  
  .blog-date {
    font-size: 0.9rem;
    color: #888;
    margin-top: 10px;
  }
  
  .blog-title {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #333;
  }
  
  .blog-author {
    font-size: 0.9rem;
    color: #666;
  }
  
  .blog-excerpt {
    font-size: 1rem;
    color: #555;
  }
  
  .blog-sidebar {
    flex: 1;
  }
  
  .widget {
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .widget-title {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #444;
  }
  
  .widget ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .widget ul li {
    margin-bottom: 10px;
    color: #555;
  }
  
  .slider {
    height: 150px;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  
  .video-widget iframe {
    border-radius: 5px;
  }
  