.privacy-policy {
    padding: 2rem 1rem;
    background-color: var(--body-color);
    color: var(--text-color);
    line-height: 1.6;
    font-size: var(--normal-font-size);
  }
  
  .privacy-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: var(--container-color);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-title {
    font-size: var(--h1-font-size);
    color: var(--first-color);
    text-align: center;
    margin-bottom: var(--mb-2);
  }
  
  h2 {
    font-size: var(--h2-font-size);
    color: var(--first-color-alt);
    margin-top: var(--mb-1-5);
  }
  
  p {
    margin-bottom: var(--mb-1);
    color: var(--text-color);
  }
  
  a {
    color: var(--first-color);
    text-decoration: underline;
  }
  
  a:hover {
    color: var(--first-color-alt);
  }
  
  @media screen and (max-width: 768px) {
    .privacy-container {
      padding: 1.5rem;
    }
  
    .privacy-title {
      font-size: var(--h2-font-size);
    }
  
    h2 {
      font-size: var(--h3-font-size);
    }
  }
  