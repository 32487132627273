/* Container styles */
.batch-details__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: var(--body-font);
}

/* Batch year selector styles */
.batch-details__header {
  text-align: center;
  margin-bottom: 20px;
}

.batch-details__year-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.batch-details__year-button {
  padding: 5px 15px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  font-family: var(--body-font);
  cursor: pointer;
  color: #333;
}

.batch-details__year-button.active {
  background-color: #ff6a00;
  color: #fff;
}

.batch-details__year-button:not(.active) {
  background-color: #f0e0d0;
}

/* Batch card styles */
.batch-details__card {
  display: grid;
  grid-template-columns: 1fr;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 900px;
  margin: 20px 0;
}

.batch-details__left {
  padding: 20px;
}

.batch-details__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.batch-details__info {
  padding: 20px;
}

.batch-details__guidance {
  color: #007bff;
  font-weight: bold; 
  font-family: var(--body-font);
}

.batch-details__name {
  font-size: 1.5rem;
  font-family: var(--body-font);
  margin: 10px 0;
}

.batch-details__features {
  list-style: none;
  padding: 0;
  font-size: 0.9rem;
  font-family: var(--body-font);
  color: #555;
}

.batch-details__features li {
  margin-bottom: 5px;
}

/* Buttons */
.batch-details__buttons {
  margin-top: 15px;
}

.batch-details__learn-more,
.batch-details__view-timetable {
  padding: 8px 16px;
  font-size: 0.9rem;
  font-family: var(--body-font);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.batch-details__learn-more {
  background-color: #007bff;
  color: #fff;
}

.batch-details__view-timetable {
  background-color: #e0e0e0;
  color: #333;
}

/* Responsive Design */
@media (min-width: 768px) {
  .batch-details__card {
    grid-template-columns: 1fr 2fr;
  }
  .batch-details__left {
    width: 400px;
  }
}
